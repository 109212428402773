import {
  buildLink,
} from '@amazeelabs/react-framework-bridge/gatsby';

export const MainMenuItems = [
  {
    title: 'About Us',
    Link: buildLink({href: '/about', title:'About us'}),
  },
  {
    title: 'User Instructions',
    Link: buildLink({href: '/user-instructions', title:'User Instructions'}),
  },
  {
    title: 'Links',
    Link: buildLink({href: '/links', title:'Links'}),
  },
  {
    title: 'Stories',
    Link: buildLink({href: '/stories', title:'Stories'}),
  },
  {
    title: 'Contact Us',
    Link: buildLink({href: '/contact', title:'Contact Us'}),
  }
];
