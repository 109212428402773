import { Route } from '@amazeelabs/react-framework-bridge';
import { buildHtml } from '@amazeelabs/react-framework-bridge/gatsby';
import { ContentRoute } from '@geored/ui';
import React from 'react';
import { StandardFrame } from '../common/standard-frame';
import { SEO } from '../components/SEO';

// @ts-ignore
export const Head = ({ children }) => (
  <>
    <SEO currentPath={'go-circular'} title={'Go Circular | TEAM HOLCIM-PRB'} />
    {children}
  </>
);

export default function NotFound() {
  return (
    <StandardFrame>
      <Route
        definition={ContentRoute}
        input={{
          body: [
            {
              key: 'pageTitles',
              input: {
                topTitle: 'Sorry',
                title: 'That page was not found',
                contain: false,
              },
            },
            {
              key: 'prose',
              input: {
                Content: buildHtml(`
                  <p><a href="/">Click here</a> to go to the home page.</p>
              `),
              },
            },
          ],
        }}
        intl={{ locale: 'en', defaultLocale: 'en' }}
      />
    </StandardFrame>
  );
}
